.skills__container {
    display: grid;
    grid-template-columns: 1fr;
    gap: 2rem;
    max-width: 1200px;
    margin: 0 auto; /* Center container */
}

.skills__content {
    background-color: var(--container-color);
    border: 1px solid rgba(0, 0, 0, 0.1);
    padding: 2rem 4rem;
    border-radius: 1.25rem;
    /* box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05); */
    max-width: 100%;
}

.skills__title {
    font-size: var(--normal-font-size);
    font-weight: var(--font-medium);
    text-align: center;
    margin-bottom: 2rem;
    text-transform: uppercase;
}

.skills__box {
    display: flex;
    flex-wrap: wrap;
    gap: 2.5rem;
}

.skills__group {
    flex: 1;
    display: grid;
    gap: 1.5rem;
}

.skills__data {
    display: flex;
    align-items: center;
    gap: 1rem;
}

.skills__icon {
    width: 60px;
    height: 40px;
    color: rgba(0, 0, 0, 0.8);
    font-size: 2rem; /* Adjust icon size */
    display: flex;
    justify-content: center;
    align-items: center;
}

.skills__progress-bar {
    background-color: #f0f0f0;
    border-radius: 5px;
    overflow: hidden;
    height: 8px; /* Adjusted for better height */
    margin: 0.5rem 0;
}

.skills__progress {
    height: 100%;
    border-radius: 5px;
    transition: width 0.3s;
}

.skills__info {
    flex: 1;
}

.skills__name {
    font-size: var(--normal-font-size);
    font-weight: var(--font-medium);
}

.skills__level {
    font-size: var(--smaller-font-size);
    text-align: right;
    margin-top: 0.25rem;
    color: var(--text-color-dark);
}

/* Media Queries */
@media screen and (max-width: 1200px) {
    /* Reduce padding on medium-sized screens */
    .skills__content {
        padding: 2rem 3rem;
    }
}

@media screen and (max-width: 992px) {
    /* Adjust layout for smaller screens (tablets and small desktops) */
    .skills__box {
        flex-direction: column; /* Stack skill groups vertically */
        gap: 2rem;
    }

    .skills__content {
        padding: 1.5rem 2rem; /* Reduced padding */
    }
}

@media screen and (max-width: 768px) {
    /* Reduce padding and font sizes for tablets */
    .skills__content {
        padding: 1rem 1.5rem;
    }

    .skills__title {
        font-size: 1.25rem; /* Slightly smaller title */
    }

    .skills__name {
        font-size: 1rem;
    }

    .skills__progress-bar {
        height: 8px; /* Thinner progress bar */
    }
}

@media screen and (max-width: 576px) {
    /* For small devices (mobile phones) */
    .skills__content {
        padding: 1rem; /* Further reduce padding */
    }

    .skills__box {
        gap: 1.5rem;
    }

    .skills__icon {
        width: 35px;
        height: 35px; /* Smaller icons */
    }

    .skills__progress-bar {
        height: 7px; /* Further reduced progress bar height */
    }

    .skills__title {
        font-size: 1rem; /* Smaller title */
    }

    .skills__name {
        font-size: 0.9rem; /* Smaller skill name font size */
    }

    .skills__level {
        font-size: 0.8rem; /* Smaller level font size */
    }
}

@media screen and (max-width: 350px) {
    /* For very small devices (extra small mobile phones) */
    .skills__content {
        padding: 0.75rem; /* Very minimal padding */
    }

    .skills__name {
        font-size: 0.85rem; /* Smallest skill name */
    }

    .skills__progress-bar {
        height: 6px; /* Thinner progress bar */
    }

    .skills__level {
        font-size: 0.75rem; /* Very small level text */
    }
}
