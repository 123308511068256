.project__filters {
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 0.75rem;
    margin-bottom: var(--mb-2);
}

.project__item {
    color: var(--title-color);
    padding: 0.25rem 0.75rem;

    font-weight: var(--font-medium);
    border-radius: 0.5rem;
    text-transform: capitalize;
}

.project__item:hover {
    background-color: var(--title-color);
    color: var(--container-color);
}

.project__container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 20px;
    padding: 20px;
}

.project__card {
    background-color: #f8f8f8; /* light background for card */
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
}

.project__card:hover {
    transform: scale(1.05);
}

.project__description {
    margin: 10px 0;
    color: #555;
}

.project__links {
    display: flex;
    gap: 10px;
    margin-top: 10px;
}


.project__img {
    width: 100%;
    height: auto;
    border-radius: 10px;
    margin-bottom: 15px;
}
.project__title {
    font-size: 1.2rem;
    font-weight: bold;
    margin-bottom: 10px;
    text-align: center;
}

.project__buttons {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 10px;
}

.project__button {
    color: #333;
    text-decoration: none;
    font-size: 1rem;
    display: flex;
    align-items: center;
    gap: 5px;
}

.project__button-icon {
    font-size: 1.2rem;
    transition: 0.4s;
}

.project__button:hover .project__button-icon{
    transform: translateX(0.25rem);
}

/* ACTIVE PROJECT */
.active__project {
    background-color: var(--title-color);
    color: var(--container-color);
}

/* BREAK POINTS */
@media screen and (max-width: 992px) {
    .project__container {
        gap: 1.25rem;
    }

    .project__card {
        padding: 1rem;
    }

    .project__img {
        margin-bottom: 0.75rem;
    }

    .project__title {
        margin-bottom: 0.25rem;
    }
}

@media screen and (max-width: 768px) {
    .project__container {
        grid-template-columns: max-content;
    }
}

@media screen and (max-width: 576px) {
    .project__container {
        grid-template-columns: 1fr;
    }

    .project__img {
        width: 100%;
    }
}

@media screen and (max-width: 350px) {
    .project__item {
        font-size: var(--small-font-size);
    }

    .project__filters {
        column-gap: 0.25rem;
    }
}
